import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ScrollableFeed from "react-scrollable-feed";
import {
  isSameSender,
  isSameSenderMargin,
  isSameUser,
  isLastMessage,
} from "../utils/logics";
import { Tooltip } from "@chakra-ui/tooltip";
import { Avatar } from "@chakra-ui/avatar";
import { IoMdDownload } from "react-icons/io";
import "../pages/home.css";
import {
  FaAngleDown,
  FaChevronDown,
  FaCircleChevronDown,
} from "react-icons/fa6";
import { timeSinceNew } from "../utils/logics";
import { RxCross2 } from "react-icons/rx";
import { IoCheckmark, IoCheckmarkDoneOutline } from "react-icons/io5";
import "../assets/css/scrollbar.css";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Group from "./Group";
import ForwordModel from "./ForwordModel";
import { sendMessage } from "../apis/messages";

function UserChatActivity({ messages, onForward }) {
  const scrollRef = useRef(null);
  const activeUser = useSelector((state) => state.activeUser);
  const [menuOpenId, setMenuOpenId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const menuRef = useRef(null);
  const [showGroup, setShowGroup] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  // const handleGroupOpen = () => setShowGroup(true);
  const handleGroupClose = () => setShowGroup(false);

  // console.log(selectedMessageId);
  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollToBottom();
    }
  };

  function linkify(text) {
    var urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(urlRegex, function (url) {
      return (
        '<a href="' +
        url +
        '" target="_blank" class="font-extralight text-sm hover:underline">' +
        url +
        "</a>"
      );
    });
  }

  const handleButtonClick = (id) => {
    setMenuOpenId(menuOpenId === id ? null : id);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpenId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const handleGroupOpen = async (messageId) => {
    setShowGroup(true);
    setSelectedMessageId(messageId);
  };

  return (
    <>
      <ScrollableFeed className="" ref={scrollRef}>
        {messages &&
          messages.map((m, i) => (
            <div className="flex items-center gap-x-[6px]" key={m._id || i}>
              {(isSameSender(messages, m, i, activeUser.id) ||
                isLastMessage(messages, i, activeUser.id)) && (
                <Tooltip
                  label={m.sender?.name}
                  placement="bottom-start"
                  hasArrow
                >
                  <Avatar
                    style={{ width: "32px", height: "32px" }}
                    mt="43px"
                    mr={1}
                    cursor="pointer"
                    name={m.sender?.name}
                    src={m.sender?.profilePic}
                    borderRadius="25px"
                  />
                </Tooltip>
              )}
              <span
                className="tracking-wider text-[15px]  font-medium"
                style={{
                  backgroundColor: `${
                    m.sender?._id === activeUser.id ? "#dcffd7" : "#f0f0f0"
                  }`,
                  marginLeft: isSameSenderMargin(messages, m, i, activeUser.id),
                  marginTop: isSameUser(messages, m, i, activeUser.id) ? 3 : 10,
                  borderRadius: `${
                    m.sender?._id === activeUser.id
                      ? "10px 10px 0px 10px"
                      : "10px 10px 10px 0"
                  }`,
                  padding: "10px 18px",
                  maxWidth: m.ChatType === "Image" ? "280px" : "460px",
                  overflowWrap: "break-word",
                  wordBreak: "break-word",
                  color: `${
                    m.sender?._id === activeUser.id ? "#000000" : "#000000"
                  }`,
                }}
              >
                <div className=" flex justify-between">
                  <div className="text-sm  text-blue-700 cursor-pointer">
                    { m.sender?.name}
                  </div>
                  {/* {m.ChatType === "Image" ? (
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <MenuButton className="">
                          <ChevronDownIcon
                            aria-hidden="true"
                            className="-mr-1 h-5 w-5 text-gray-700"
                          />
                        </MenuButton>
                      </div>
                      <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <div className="py-1">
                          <MenuItem>
                            <a
                              href={m.ChatAttechment}
                              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                              Download
                            </a>
                          </MenuItem>
                          <MenuItem>
                            <a
                              onClick={() => handleGroupOpen(m)}
                              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer"
                            >
                              Forward
                            </a>
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </Menu>
                  ) : (
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <MenuButton className="">
                          <ChevronDownIcon
                            aria-hidden="true"
                            className="-mr-1 h-5 w-5 text-gray-700"
                          />
                        </MenuButton>
                      </div>
                      <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <div className="py-1">
                          
                          <MenuItem>
                            <a
                              onClick={() => handleGroupOpen(m)}
                              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer"
                            >
                              Forward
                            </a>
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </Menu>
                  )} */}
                </div>
                {m.ChatType === "Image" ? (
                  <img
                    src={m.ChatAttechment}
                    alt="image"
                    style={{
                      maxWidth: "100%",
                      borderRadius: "10px",
                      display: "block",
                      cursor: "pointer",
                    }}
                    onClick={() => openModal(m.ChatAttechment)}
                  />
                ) : m.ChatType === "Document" ? (
                  <div>
                    <div className="flex justify-between mb-3">
                      <span className="text-md">📑</span>
                      <span className=" p-1 border rounded-full border-slate-400 text-slate-400 ">
                        <a href={m.ChatAttechment} download>
                          <IoMdDownload className="h-4 w-4" />
                        </a>
                      </span>
                    </div>
                    <div>{m.FileName}</div>
                  </div>
                ) : (
                  <div
                    className="font-extralight text-sm"
                    dangerouslySetInnerHTML={{ __html: linkify(m.message) }}
                  />
                )}
                <span className="flex text-xs justify-end text-gray-500 font-extralight mt-1 ">
                  {timeSinceNew(new Date(Date.parse(m.updatedAt)))}
                  {m.sender?._id === activeUser.id && (
                    <span className="ml-2">
                      {/* {m.seenStatus === true ? (
                        <IoCheckmarkDoneOutline size={16} color="blue" />
                      ) : (
                        <IoCheckmarkDoneOutline size={16} />
                      )} */}

                      {m.seenStatus === false && m.deliveredStatus === false ? (
                        <IoCheckmark />
                      ) : m.seenStatus === false &&
                        m.deliveredStatus === true ? (
                        <IoCheckmarkDoneOutline size={16} />
                      ) : (
                        <IoCheckmarkDoneOutline size={16} color="blue" />
                      )}
                    </span>
                  )}
                </span>
              </span>
            </div>
          ))}
      </ScrollableFeed>
      {isModalOpen && (
        <div
          id="extralarge-modal"
          className="fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-100% flex justify-center items-center"
        >
          <div className="relative w-full max-w-full h-full overflow-y-auto">
            <div className="relative bg-white shadow dark:bg-gray-700">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-500 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={closeModal}
                >
                  <RxCross2 size={25} />
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 md:p-5 flex justify-center items-center">
                {selectedImage && (
                  <img
                    src={selectedImage}
                    alt="selected"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <ForwordModel
        open={showGroup}
        onClose={handleGroupClose}
        messageId={selectedMessageId}
      />
    </>
  );
}

export default UserChatActivity;
