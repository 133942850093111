import React, { useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { setShowProfile } from "../redux/profileSlice";
import { IoMdLogOut, IoIosCamera } from "react-icons/io";
import InputEdit from "./profile/InputEdit";
import { updateUser, profiepicturechnage, API } from "../apis/auth";
import { toast } from "react-toastify";
import { setUserNameAndBio } from "../redux/activeUserSlice";
import axios from "axios";

function Profile(props) {
  const dispatch = useDispatch();
  const { showProfile } = useSelector((state) => state.profile);
  const activeUser = useSelector((state) => state.activeUser);
  const [formData, setFormData] = useState({
    name: activeUser.name,
    bio: activeUser.bio,
    profilePic: activeUser.profilePic,
  });
  // console.log("formData", formData);
  // console.log("showProfile", showProfile);
  // console.log("activeUser", activeUser);
  
  const API = (token) =>
    axios.create({
      baseURL: process.env.REACT_APP_SERVER_URL,
      headers: { Authorization: token },
    });

  const logoutUser = () => {
    toast.success("Logout Successfull!");
    localStorage.removeItem("userToken");
    window.location.href = "/login";
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // console.log("form data", formData);
  };

  const submit = async () => {
    dispatch(setUserNameAndBio(formData));
    // console.log("bio data", formData);
    // console.log("active user Id", activeUser.id);
    toast.success("Updated!");
    await updateUser(activeUser.id, formData);
  };

  const [isHovered, setIsHovered] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    setSelectedFile(URL.createObjectURL(file));
    const formData = new FormData();
    formData.append("image", file);
    toast.success("Setting Profile Photo");
    try {
      const token = localStorage.getItem("userToken");
      const { data } = await API(token).post(
        `/api/users/profilepicturechange/${activeUser.id}`,
        formData
      );
      setFormData({
        ...formData,
        name: activeUser.name,
        bio: activeUser.bio,
        profilePic: data.details,
      });
      console.log(formData);
      toast.success(" Profile Photo Set");
      return data;
    } catch (error) {
      console.log("error in update profile picture");
      toast.error("Something Went Wrong.try Again!");
    }
  };

  return (
    <div
      style={{ transition: showProfile ? "0.3s ease-in-out" : "" }}
      className={props.className}
    >
      <div className="absolute  w-[100%]">
        <div className="bg-[#166e48] pt-12 pb-3">
          <button
            onClick={() => dispatch(setShowProfile(false))}
            className="flex items-center"
          >
            <IoArrowBack
              style={{ color: "#fff", width: "30px", height: "20px" }}
            />
            <h6 className="text-[16px] text-[#fff] font-semibold">Profile</h6>
          </button>
        </div>
        <div className=" pt-5">
          <div className="flex items-center flex-col">
            <div
              style={{ position: "relative", display: "inline-block" }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <img
                src={formData.profilePic}
                alt="Image"
                className="w-[150px] h-[150px] rounded-[100%]"
              />
              {isHovered && (
                <div
                  className="rounded-[100%]"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <label
                    htmlFor="file-input"
                    style={{
                      color: "white",
                      cursor: "pointer",
                      textAlign: "center",
                      fontSize: "10px",
                    }}
                  >
                    <IoIosCamera
                      className="text-[#e44d4d] w-[27px] h-[23px] center"
                      style={{
                        color: "white",
                        cursor: "pointer",
                        fontSize: "10px",
                        marginLeft: "35%",
                      }}
                    />
                    Change Profile Photo
                  </label>
                  <input
                    type="file"
                    id="file-input"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                </div>
              )}
            </div>
          </div>
          <InputEdit
            type="name"
            handleChange={handleChange}
            input={formData.name}
            handleSubmit={submit}
          />

          <div>
            <div className="py-5 px-4">
              <p className="text-[10px] tracking-wide text-[#3b4a54] ">
                This is not your username or pin. This name will be visible to
                your contacts
              </p>
            </div>
          </div>
          <InputEdit
            type="bio"
            handleChange={handleChange}
            input={formData.bio}
            handleSubmit={submit}
          />
        </div>

        <div
          onClick={logoutUser}
          className="flex items-center justify-center mt-5 cursor-pointer shadow-2xl"
        >
          <IoMdLogOut className="text-[#e44d4d] w-[27px] h-[23px]" />
          <h6 className="text-[17px] text-[#e44d4d] font-semibold">Logout</h6>
        </div>
      </div>
    </div>
  );
}

export default Profile;
