import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Sidebar from "../components/partials/Sidebar";
import Header from "../components/partials/Header";
import WelcomeBanner from "../components/partials/dashboard/WelcomeBanner";
import Banner from "../components/partials/Banner";
import MemberCreateForm from "../components/MemberCreate";
import DashboardCard07 from "../components/partials/dashboard/DashboardCard07";
import { getUsers } from "../apis/auth";

function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isCreatingMember, setIsCreatingMember] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setRefreshData(!refreshData);
  };

  const handleMemberCreate = (member) => {
    console.log("Member created:", member);
    setIsCreatingMember(false);
  };

  const handleCreateNew = (member) => {
    console.log("Member created:", member);
    setIsCreatingMember(true);
    // navigate("/member-create");
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                <button
                  className="btn bg-green-600 hover:bg-green-700 text-white flex justify-between items-center px-2 py-1 rounded-sm "
                  onClick={openModal}
                >
                  <svg
                    className="w-4 h-4 fill-current opacity-50 shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="xs:block ml-2 text-sm font-medium">Add New</span>
                </button>
              </div>
            </div>
            {isModalOpen && (
              <MemberCreateForm onCreate={handleMemberCreate} closeModal={closeModal} />
            )}
            <div className="grid grid-cols-12 gap-6">
            <DashboardCard07 refreshData={refreshData}/>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
