import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BsEmojiLaughing, BsEmojiExpressionless } from "react-icons/bs";
import { registerUser } from "../apis/auth";

const defaultData = {
  firstname: "",
  lastname: "",
  email: "",
  employee_code: "",
  password: "",
};

function MemberCreate({ closeModal }) {
  const [formData, setFormData] = useState(defaultData);
  const [isLoading, setIsLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const pageRoute = useNavigate();
  const modalRef = useRef(null);

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (formData.email.includes('@') && formData.password.length > 6) {
      try {
        const { data } = await registerUser(formData);
        console.log(data);

        if (data?.token) {
          toast.success('Successfully Registered😍');
          setIsLoading(false);
          closeModal();
        } else {
          setIsLoading(false);
          toast.error('Invalid Credentials!');
        } 
      } catch (error) {
        setIsLoading(false);
        toast.error('User is already created');
        console.error('Error during registration:', error);
      }
    } else {
      setIsLoading(false);
      toast.warning('Provide valid Credentials!');
      setFormData({ ...formData, password: '' });
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div
        ref={modalRef}
        className="relative p-4 w-full max-w-2xl"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="relative bg-white rounded-sm shadow dark:bg-gray-700">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Register New Member
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-md text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={closeModal}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 md:p-5 space-y-4">
            <form onSubmit={handleOnSubmit} className="space-y-4">
              <div className="flex gap-x-2">
                <input
                  type="text"
                  name="firstname"
                  placeholder="First Name"
                  value={formData.firstname}
                  onChange={handleOnChange}
                  className="bg-gray-200 h-12 pl-3 text-gray-800 w-1/2 rounded"
                  required
                />
                <input
                  type="text"
                  name="lastname"
                  placeholder="Last Name"
                  value={formData.lastname}
                  onChange={handleOnChange}
                  className="bg-gray-200 h-12 pl-3 text-gray-800 w-1/2 rounded"
                  required
                />
              </div>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleOnChange}
                className="bg-gray-200 h-12 pl-3 text-gray-800 w-full rounded"
                required
              />
              <input
                type="text"
                name="employee_code"
                placeholder="Employee CODE"
                value={formData.employee_code}
                onChange={handleOnChange}
                className="bg-gray-200 h-12 pl-3 text-gray-800 w-full rounded"
                required
              />
              <div className="relative">
                <input
                  type={showPass ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleOnChange}
                  className="bg-gray-200 h-12 pl-3 text-gray-800 w-full rounded"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPass(!showPass)}
                  className="absolute top-3 right-4"
                >
                  {showPass ? (
                    <BsEmojiExpressionless className="text-black w-6 h-6" />
                  ) : (
                    <BsEmojiLaughing className="text-black w-6 h-6" />
                  )}
                </button>
              </div>
              <button
                type="submit"
                className="w-28 h-10 font-semibold text-white tracking-wide text-sm relative bg-gradient-to-r bg-green-600 hover:bg-green-700 rounded-sm"
              >
                {isLoading ? (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <lottie-player
                      src="https://assets2.lottiefiles.com/packages/lf20_h9kds1my.json"
                      background="transparent"
                      speed="1"
                      style={{ width: "50px", height: "50px" }}
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                ) : (
                  "Register"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberCreate;
