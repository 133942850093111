import React, { useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { searchChats, searchUsers } from "../apis/auth";
import { getChatName, getChatPhoto } from "../utils/logics";
import { fetchChats } from "../redux/chatsSlice";
import { sendMessage } from "../apis/messages";
import io from "socket.io-client";
import axios from "axios";
import Search from "./group/Search";
import { logDOM } from "@testing-library/react";

const ENDPOINT = process.env.REACT_APP_SERVER_URL;
let socket;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  pb: 1,
  borderRadius: "3px",
};

function ForwardModel({ open, onClose, messageId }) {
  const dispatch = useDispatch();
  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedChats, setSelectedChats] = useState([]);
  const { activeChat, chats } = useSelector((state) => state.chats);
  const activeUser = useSelector((state) => state.activeUser);
  const [message, setMessage] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  
  // console.log(selectedChats);

  const API = (token) =>
    axios.create({
      baseURL: process.env.REACT_APP_SERVER_URL,
      headers: { Authorization: token },
    });

  useEffect(() => {
    socket = io(ENDPOINT);
  }, []);

  // useEffect(() => {
  //   const searchChange = async () => {
  //     setIsLoading(true);
  //     const { data } = await searchUsers(search);
  //     const filteredData = data.filter(
  //       (user) =>
  //         !selectedChats.some((selectedChat) => selectedChat._id === user._id)
  //     );
  //     setSearchResults(filteredData);
  //     setIsLoading(false);
  //   };

  //   if (search) {
  //     searchChange();
  //   } else {
  //     setSearchResults([]);
  //   }
  // }, [search, selectedChats]);

  const handleCheckboxChange = (chat) => {
    if (selectedChats.includes(chat)) {
      setSelectedChats(selectedChats.filter((c) => c._id !== chat._id));
    } else {
      setSelectedChats([...selectedChats, chat]);
    }
  };

  const handleFormSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSubmit = async () => {
    setMessage(messageId);
    try {
      const token = localStorage.getItem("userToken");
      for (const chat of selectedChats) {
        let data;
        if (messageId.ChatType === "Image") {
          const response = await API(token).post(`/api/message/forwardimage`, {
            chatId: chat._id,
            message: messageId.message,
            ChatAttechment: messageId.ChatAttechment,
            FileName: messageId.FileName,
          });
          data = response.data;
        } else if (messageId.ChatType === "Document") {
          const response = await API(token).post(`/api/message/forwardDoc`, {
            chatId: chat._id,
            message: messageId.message,
            ChatAttechment: messageId.ChatAttechment,
            FileName: messageId.FileName,
          });
          data = response.data;
        } else {
          data = await sendMessage({
            chatId: chat._id,
            message: messageId.message,
          });
        }
        socket.emit("new message", data);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
    onClose();
  };

  // useEffect(() => {
  //   const searchChange = async () => {
  //     setIsLoading(true);
  //     const { data } = await searchChats(search);
  //     const filteredData = data.filter(
  //       (user) =>
  //         !selectedUsers.some((selectedUser) => selectedUser._id === user._id)
  //     );
  //     setSearchResults(filteredData);
  //     setIsLoading(false);
  //   };
  //   if (search) {
  //     searchChange();
  //   } else {
  //     setSearchResults([]);
  //   }
  // }, [search, selectedUsers]);
  
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <h5 className="text-[20px] py-2 font-semibold tracking-wide text-center text-[#fafafa] bg-[#166e48]">
          Forward Message
        </h5>

        <div className="input-area flex flex-col -space-y-1 overflow-y-scroll pt-3 pb-2 w-full px-2">
          {/* <input
            type="text"
            className="form-control"
            placeholder="Enter here..."
            name="userName"
            onChange={handleFormSearch}
          /> */}
        </div>
        <div className="flex flex-col -space-y-1 overflow-y-scroll h-[70vh] pb-1 w-full px-2">
          {/* {searchResults.length > 0
            ? searchResults.slice(0, 10).map((e) => (
                <div
                  key={e._id}
                  className="flex items-center justify-between hover:bg-gray-100 py-2 px-2"
                >
                  <div className="flex items-center gap-x-2">
                    <img
                      className="w-[42px] h-[42px] rounded-[25px]"
                      src={
                        !e.profilePic
                          ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                          : e.profilePic
                      }
                      alt=""
                    />
                    <div className="flex flex-col">
                      <p className="text-[15px] text-[#111b21] tracking-wide font-medium">
                        {e.name}
                      </p>
                      <p className="text-[12px] text-[#68737c] tracking-wide font-normal">
                        {e.employee_code}
                      </p>
                    </div>
                  </div>
                  <input
                    type="checkbox"
                    className="accent-[#166e48] h-5"
                    checked={selectedChats.includes(e)}
                    onChange={() => handleCheckboxChange(e)}
                  />
                </div>
              ))
            :  */}
            {chats.slice(0, 10).map((chat) => (
                <div
                  key={chat._id}
                  className="flex items-center justify-between sm:gap-x-1 md:gap-x-1 mt-3 cursor-pointer py-2 px-2 hover:bg-gray-100"
                >
                  <div className="flex items-center gap-x-3 sm:gap-x-1 md:gap-x-3 justify-between w-full">
                    <div className="flex justify-between sm:gap-x-2 md:gap-x-2">
                      <img
                        className="w-10 h-10 sm:w-10 sm:h-10 rounded-[30px] shadow-lg object-cover"
                        src={getChatPhoto(chat, activeUser)}
                        alt="profilePic"
                      />
                      <h5 className="text-[13.6px] sm:text-[16px] text-[#2b2e33] font-bold mt-1">
                        {getChatName(chat, activeUser)}
                      </h5>
                    </div>
                    <input
                      type="checkbox"
                      className="accent-[#166e48] h-5"
                      checked={selectedChats.includes(chat)}
                      onChange={() => handleCheckboxChange(chat)}
                    />
                  </div>
                </div>
              ))}
        </div>
        <div className="flex justify-end gap-x-3 mt-3">
          <button
            type="submit"
            className="bg-[#166e48] text-[#fff] text-[12px] font-medium px-3 py-1 mr-3 tracking-wide"
            onClick={handleSubmit}
          >
            Send
          </button>
        </div>
      </Box>
    </Modal>
  );
}

export default ForwardModel;
