import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import Sidebar from "../components/partials/Sidebar";
import Header from "../components/partials/Header";
import "../assets/css/app.css";
import { getUsers } from "../apis/auth";
import { fetchChatActivity, fetchMessages } from "../apis/chat"; // Import fetchMessages
import MessageHistory from "../components/MessageHistory";
import { fetchUserChat } from "../apis/messages";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import UserChatActivity from "../components/UserChatActivity";

const ChatActivity = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser1, setSelectedUser1] = useState(null);
  const [selectedUser2, setSelectedUser2] = useState(null);
  const [chatActivity, setChatActivity] = useState([]);
  const [chatActivityOptions, setChatActivityOptions] = useState([]);
  const [messages, setMessages] = useState([]);
  const [messageContainer, setMessageContainer] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await getUsers();
        const formattedUsers = data.data.map((user) => ({
          value: user._id,
          label: user.name,
        }));
        setUsers(formattedUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  const handleUser1Change = async (option) => {
    setSelectedUser1(option);
    try {
      const response = await fetchChatActivity(option.value);
      setChatActivity(response);
      const formattedChatActivity = response
        .filter((activity) => !activity.isGroup)
        .map((activity) => ({
          value: activity._id,
          label: activity.users.find((user) => user._id !== option.value).name,
        }));
      setChatActivityOptions(formattedChatActivity);
    } catch (error) {
      console.error("Error fetching chat activity:", error);
    }
  };

  const fetchMessages = async () => {
    if (selectedUser2) {
      // console.log(startDate);
      try {
        const messages = await fetchUserChat(selectedUser2.value, startDate);
        setMessages(messages);
        setMessageContainer(true);
        // console.log("Selected date:", startDate);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div
            className="content-wrapper transition-all duration-150 ltr:ml-[248px] rtl:mr-[248px]"
            id="content_wrapper"
          >
            <div className="page-content">
              <div
                className="transition-all duration-150 container-fluid"
                id="page_layout"
              >
                <div id="content_layout">
                  <div className="space-y-6">
                    <div className="card shadow-lg">
                      <div className="card-body flex flex-col p-6">
                        <header className="flex mb-5 items-center border-b border-slate-100 dark:border-slate-700 pb-5 -mx-6 px-6">
                          <div className="flex-1">
                            <div className="card-title text-slate-900 dark:text-white">
                              Chat Activity
                            </div>
                          </div>
                        </header>
                        <div className="card-text h-full space-y-6">
                          <div className="grid md:grid-cols-3 gap-6">
                            <div>
                              <label htmlFor="select1" className="form-label">
                                User 1
                              </label>
                              <Select
                                options={users}
                                onChange={handleUser1Change}
                                value={selectedUser1}
                                id="select1"
                              />
                            </div>
                            <div>
                              <label htmlFor="select2" className="form-label">
                                User 2
                              </label>
                              <Select
                                options={chatActivityOptions}
                                onChange={(option) => setSelectedUser2(option)}
                                value={selectedUser2}
                                id="select2"
                              />
                            </div>
                            <div>
                              <label htmlFor="date" className="form-label">
                                Date
                              </label>
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                className=" border border-gray-300 text-gray-600 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Select date"
                                dateFormat="yyyy-MM-dd"
                                showTimeSelect={false}
                              />

                              {/* <div className="relative max-w-sm">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                  <svg
                                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                  </svg>
                                </div>
                                <input
                                  datepicker
                                  id="default-datepicker"
                                  type="text"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="Select date"
                                />
                              </div> */}
                            </div>
                          </div>
                          <button
                            className="py-1 px-3 rounded-sm justify-center btn-success"
                            onClick={fetchMessages}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                    {messageContainer && (
                      <div className="card min-h-96 max-h-96 overflow-auto">
                        <div className="card-body flex flex-col p-6">
                          <div className="card-text h-full space-y-6">
                            {/* <MessageHistory messages={messages} /> */}
                            <UserChatActivity messages={messages}/>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ChatActivity;
