import React from "react";
import { useState, useEffect } from "react";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { googleAuth, loginUser, validUser } from "../apis/auth";
import { Link, useNavigate } from "react-router-dom";
import { BsEmojiLaughing, BsEmojiExpressionless } from "react-icons/bs";
import { toast } from "react-toastify";
import { Box, Modal } from "@mui/material";
import PasswordUpdateModal from "../components/PasswordUpdateModal";
import { useSelector } from "react-redux";

const defaultData = {
  email: "",
  password: "",
};

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  //   boxShadow: 24,

  pb: 1,
  //   borderRadius: "5px",
};
function Login() {
  const [formData, setFormData] = useState(defaultData);
  const [isLoading, setIsLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Manage modal visibility
  const pageRoute = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const { activeUser } = useSelector((state) => state);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };

  const googleSuccess = async (res) => {
    if (res?.profileObj) {
      console.log(res.profileObj);
      setIsLoading(true);
      const response = await googleAuth({ tokenId: res.tokenId });
      setIsLoading(false);
      console.log("response :" + res);
      if (response.data.token) {
        localStorage.setItem("userToken", response.data.token);
        pageRoute("/chats");
      }
    }
  };

  const googleFailure = (error) => {
    // toast.error("Something went Wrong.Try Again!")
  };

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    if (formData.password.length >= 6) {
      setIsLoading(true);
      const { data } = await loginUser(formData);

      // Check for the "User is not online" response
      if (data?.message === "User is not online") {
        setIsLoading(false);
        const userConfirmed = window.confirm(
          "You have to change your password"
        );

        if (userConfirmed) {
          setIsModalOpen(true);
        }
        return;
      }

      if (data?.token) {
        localStorage.setItem("userToken", data.token);
        toast.success("Succesfully Login!");
        setIsLoading(false);
        pageRoute("/chats");
      } else {
        setIsLoading(false);
        toast.error("Invalid Credentials!");
        setFormData({ ...formData, password: "" });
      }
    } else {
      setIsLoading(false);
      toast.warning("Provide valid Credentials!");
      setFormData(defaultData);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      alert("New password and confirm new password do not match!");
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/auth/update-password/${activeUser.id}`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ currentPassword, newPassword }),
        }
      );

      const data = await response.json();
      if (data.error) {
        toast.warning("Wrong Current Password!");
      } else {
        toast.success("Password Update Succesfully!");
        handleClose();
      }
    } catch (error) {
      toast.error("Error Updating Password!");
    }
  };

  const handlePasswordUpdate = (e) => {
    e.preventDefault();
    // Add logic to update the password using form inputs
    setIsModalOpen(false);
    toast.success("Password updated successfully!");
  };

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: process.env.REACT_APP_CLIENT_ID,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
    const isValid = async () => {
      const data = await validUser();
      if (data?.user) {
        window.location.href = "/chats";
      }
    };
    isValid();
  }, []);

  return (
    <>
      <div className="bg-[#121418] w-[100vw] h-[100vh] flex justify-center items-center">
        <div className="w-[90%] sm:w-[400px] pl-0 ml-0 h-[450px] sm:pl-0 sm:ml-9 mt-20 relative">
          {/* Form structure */}
          <div className="absolute -top-5 left-0">
            <img
              src="https://www.srfc.org.in/upload/logo.png"
              className="w-[80%] mb-5 center"
            ></img>
            <h3 className=" text-[25px] font-bold tracking-wider text-[#fff]">
              SRFC CHAT
            </h3>
            {/* <p className="text-[#fff] text-[12px] tracking-wider font-medium">
              No Account ?{" "}
              <Link
                className="text-[rgba(0,195,154,1)] underline"
                to="/register"
              >
                Sign up
              </Link>
            </p> */}
          </div>
          <form
            className="flex flex-col gap-y-3 mt-[37%]"
            onSubmit={formSubmit}
          >
            <div>
              <input
                className="w-[100%] sm:w-[80%] bg-[#222222] h-[50px] pl-3 text-[#ffff]"
                onChange={handleOnChange}
                name="email"
                type="text"
                placeholder="Email / SRFC Code "
                value={formData.email}
                required
              />
            </div>
            <div className="relative">
              <input
                className="w-[100%] sm:w-[80%] bg-[#222222] h-[50px] pl-3 text-[#ffff]"
                onChange={handleOnChange}
                type={showPass ? "text" : "password"}
                name="password"
                placeholder="Password"
                value={formData.password}
                required
              />
              <button type="button" onClick={() => setShowPass(!showPass)}>
                {!showPass ? (
                  <BsEmojiLaughing className="text-[#fff] absolute top-3 right-5 sm:right-24 w-[30px] h-[25px]" />
                ) : (
                  <BsEmojiExpressionless className="text-[#fff] absolute top-3 right-5 sm:right-24 w-[30px] h-[25px]" />
                )}
              </button>
            </div>

            <button
              style={{
                background:
                  "linear-gradient(90deg, rgba(0,195,154,1) 0%, rgba(224,205,115,1) 100%)",
              }}
              className="w-[100%] sm:w-[80%] h-[50px] font-bold text-[#121418] tracking-wide text-[17px] relative"
              type="submit"
            >
              <div
                style={{ display: isLoading ? "" : "none" }}
                className="absolute -top-[53px] left-[27%] sm:-top-[53px] sm:left-[56px]"
              >
                {/* Loading animation */}
                <lottie-player
                  src="https://assets2.lottiefiles.com/packages/lf20_h9kds1my.json"
                  background="transparent"
                  speed="1"
                  style={{ width: "200px", height: "160px" }}
                  loop
                  autoplay
                ></lottie-player>
              </div>
              <p style={{ display: isLoading ? "none" : "block" }}>Login</p>
            </button>
          </form>

          {/* Modal for password update */}
          <Modal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <h5 className="text-[20px] py-2 font-semibold tracking-wide text-center rounded-t-md text-[#fafafa] bg-[#166e48]">
                Password Update
              </h5>
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col overflow-y-scroll scrollbar-hide h-[50vh] pb-1 w-full px-2">
                  <div className="flex flex-col space-y-4 mt-4 px-3">
                    <div className="input-area">
                      <label htmlFor="current-password" className="form-label">
                        Current Password
                        <span className="text-red-700 text-lg">*</span>
                      </label>
                      <input
                        id="current-password"
                        type="password"
                        className="form-control"
                        placeholder="Current Password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                    </div>
                    <div className="input-area">
                      <label htmlFor="new-password" className="form-label">
                        New Password
                        <span className="text-red-700 text-lg">*</span>
                      </label>
                      <input
                        id="new-password"
                        type="password"
                        className="form-control"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </div>
                    <div className="input-area">
                      <label
                        htmlFor="confirm-new-password"
                        className="form-label"
                      >
                        Confirm New Password
                        <span className="text-red-700 text-lg">*</span>
                      </label>
                      <input
                        id="confirm-new-password"
                        type="password"
                        className="form-control"
                        placeholder="Confirm New Password"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                      />
                    </div>
                    <div className="flex justify-end gap-x-3 mt-3">
                      <button
                        type="submit"
                        className="bg-[#880808] hover:bg-[#A52A2A] transition delay-150 px-4 py-1 text-[10.6px] tracking-wide text-[#fff]"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Login;
