import React, { useEffect, useState } from "react";
import { loginAdmin, validUser } from "../apis/auth";
import { useNavigate } from "react-router-dom";
import { BsEmojiLaughing, BsEmojiExpressionless } from "react-icons/bs";
import { toast } from "react-toastify";

const defaultData = {
  email: "",
  password: "",
};

function Admin() {
  const [formData, setFormData] = useState(defaultData);
  const [isLoading, setIsLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem("userToken");
      if (token) {
        const isValid = await validUser(token);
        if (isValid) {
          navigate("/admin");
        } else {
          localStorage.removeItem("userToken");
        }
      }
    };
    checkToken();
  }, [navigate]);

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    if (formData.password.length > 6) {
      setIsLoading(true);
      const { data } = await loginAdmin(formData);
      if (data?.token) {
        localStorage.setItem("userToken", data.token);
        toast.success("Successfully Logged In!");
        setIsLoading(false);
        navigate("/admin");
      } else {
        setIsLoading(false);
        toast.error("Invalid Credentials!");
        setFormData({ ...formData, password: "" });
      }
    } else {
      setIsLoading(false);
      toast.warning("Provide valid Credentials!");
      setFormData(defaultData);
    }
  };

  return (
    <div className="bg-[#121418] w-[100vw] h-[100vh] flex justify-center items-center">
      <div className="w-[90%] sm:w-[400px] pl-0 ml-0 h-[450px] sm:pl-0 sm:ml-9 mt-20 relative">
        <div className="absolute -top-5 left-0">
          <img
            src="https://www.srfc.org.in/upload/logo.png"
            className="w-[80%] mb-5 center"
          />
          <h3 className="text-[25px] font-bold tracking-wider text-[#fff]">
            SRFC CHAT ADMIN
          </h3>
        </div>
        <form className="flex flex-col gap-y-3 mt-[35%]" onSubmit={formSubmit}>
          <div>
            <input
              className="w-[100%] sm:w-[80%] bg-[#222222] h-[50px] pl-3 text-[#ffff]"
              onChange={handleOnChange}
              name="email"
              type="text"
              placeholder="User Id"
              value={formData.email}
              required
            />
          </div>
          <div className="relative">
            <input
              className="w-[100%] sm:w-[80%] bg-[#222222] h-[50px] pl-3 text-[#ffff]"
              onChange={handleOnChange}
              type={showPass ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={formData.password}
              required
            />
            {!showPass ? (
              <button type="button">
                <BsEmojiLaughing
                  onClick={() => setShowPass(!showPass)}
                  className="text-[#fff] absolute top-3 right-5 sm:right-24 w-[30px] h-[25px]"
                />
              </button>
            ) : (
              <button type="button">
                <BsEmojiExpressionless
                  onClick={() => setShowPass(!showPass)}
                  className="text-[#fff] absolute top-3 right-5 sm:right-24 w-[30px] h-[25px]"
                />
              </button>
            )}
          </div>

          <button
            style={{
              background:
                "linear-gradient(90deg, rgba(0,195,154,1) 0%, rgba(224,205,115,1) 100%)",
            }}
            className="w-[100%]  sm:w-[80%] h-[50px] font-bold text-[#121418] tracking-wide text-[17px] relative"
            type="submit"
          >
            <div
              style={{ display: isLoading ? "" : "none" }}
              className="absolute -top-[53px] left-[27%] sm:-top-[53px] sm:left-[56px]"
            >
              <lottie-player
                src="https://assets2.lottiefiles.com/packages/lf20_h9kds1my.json"
                background="transparent"
                speed="1"
                style={{ width: "200px", height: "160px" }}
                loop
                autoplay
              ></lottie-player>
            </div>
            <p
              style={{ display: isLoading ? "none" : "block" }}
              className="test-[#fff]"
            >
              Login
            </p>
          </button>
        </form>
      </div>
    </div>
  );
}

export default Admin;
