import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCX9K84AiKWWPBOjQGNzXqPpSDmwh2a8Ms",
  authDomain: "chat-srfc.firebaseapp.com",
  projectId: "chat-srfc",
  storageBucket: "chat-srfc.appspot.com",
  messagingSenderId: "761631619461",
  appId: "1:761631619461:web:13a169b6a133b515932cec",
  measurementId: "G-LQGMR4D1H5",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging, getToken, onMessage };
