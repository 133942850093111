import React, { useState } from 'react';

function Banner() {
  return (
    <>
    Banner
    </>
  );
}

export default Banner;