import axios from 'axios';
const API = (token) =>
  axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: { Authorization: token },
  });
  
export const sendMessage = async (body) => {
  try {
    const token = localStorage.getItem('userToken');
    const { data } = await API(token).post('/api/message/', body);
    return data;
  } catch (error) {
    console.log('error in sendmessage api' + error);
  }
};

export const fetchMessages = async (id, page = 1, limit = 50) => {
  try {
    const token = localStorage.getItem('userToken');
    const { data } = await API(token).get(`/api/message/${id}`, {
      params: {page, limit}
    });
    return data;
  } catch (error) {
    console.log('error in fetch Message API ' + error);
  }
};

export const fetchUserChat = async (id, date) => {
  try {
    const token = localStorage.getItem('userToken');
    const { data } = await API(token).get(`/api/message/users/${id}`, {
      params: { date }, // Pass date as a query parameter
    });
    return data;
  } catch (error) {
    console.log('Error in fetch Message API: ' + error);
    throw error;
  }
};

