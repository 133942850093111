import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

function PasswordReset({ user, closeResetModal }) {
  const pageRoute = useNavigate();
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => state.activeUser);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  console.log("user", user);

  const generateRandomPassword = (length = 6) => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      password += chars[randomIndex];
    }
    return password;
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ empId: user.employee_code, newPassword }),
    });
    const data = await response.json();
    if (response.ok) {
      console.log("Password reset successfully:", data);
      alert("Password reset successfully.");
    } else {
      console.log("Password reset failed:", data);
      alert("Password reset failed: " + data.error);
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeResetModal();
    }
  };

  useEffect(() => {
    const randomPassword = generateRandomPassword();
    setNewPassword(randomPassword);
    setConfirmNewPassword(randomPassword);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div
        ref={modalRef}
        className="relative p-4 w-[70vh] max-w-2xl"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="relative bg-white rounded-sm shadow dark:bg-gray-700">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Reset Password
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-md text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={closeResetModal}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 md:p-5 space-y-4">
            <form className="space-y-4" onSubmit={updatePassword}>
              <label>New Password</label>
              <input
                type="text"
                name="password"
                placeholder="Enter Here"
                className="bg-gray-200 h-12 pl-3 text-gray-800 w-full rounded"
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <label>Confirm New Password</label>
              <input
                type="text"
                name="confirm password"
                placeholder="Enter Here"
                className="bg-gray-200 h-12 pl-3 text-gray-800 w-full rounded"
                required
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
              <div className="flex justify-between items-center">
                <button
                  type="submit"
                  className="w-24 h-8 font-semibold text-white tracking-wide text-sm relative bg-gradient-to-r bg-green-600 hover:bg-green-700 rounded-sm"
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordReset;
