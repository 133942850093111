import Login from './pages/Login';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Regsiter from './pages/Regsiter';
import Home from './pages/Home';
import Start from './components/Start';
import Admin from './pages/Admin';
import Master from './pages/Master';
import ChatActivity from './pages/ChatActivity';
import GroupChatActivity from './pages/groupChatActivity';
import PrivateRoute from './components/PrivateRoute';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    const handlePopState = (event) => {
      if (window.location.pathname === '/chats') {
        // Prevent backward navigation
        window.history.pushState(null, null, window.location.href);
      }
    };

    if (window.location.pathname === '/chats') {
      window.history.pushState(null, null, window.location.href);
      window.addEventListener('popstate', handlePopState);
    }

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);
  return (
    <div className="bg-[#F8F4EA]">
      <Router>
        <Routes>
          <Route exact path="/admin/login" element={<Admin />} />
          <Route path="/admin" element={<PrivateRoute element={<Master />} />} />
          <Route path="/admin/chat" element={<PrivateRoute element={<ChatActivity />} />} />
          <Route path="/admin/group-chat" element={<PrivateRoute element={<GroupChatActivity />} />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Regsiter />} />
          <Route exact path="/chats" element={<Home />} />
          <Route exact path="/" element={<Start />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
