import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActiveChat, fetchChats } from "../redux/chatsSlice";
import { getChatName, getChatPhoto, timeSinceNew } from "../utils/logics";
import NoContacts from "./ui/NoContacts";
import SkeletonLoading from "./ui/SkeletonLoading"; // Assuming you have this component
import Loading from "../components/ui/Loading";
import { io } from "socket.io-client";
import { validUser } from "../apis/auth";
const ENDPOINT = process.env.REACT_APP_SERVER_URL;
const socket = io(ENDPOINT);

var aDay = 24 * 60 * 60 * 1000;

function Contacts() {
  const { chats, activeChat } = useSelector((state) => state.chats);
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => state.activeUser);
  const [visibleChats, setVisibleChats] = useState(10);
  const [loading, setLoading] = useState(true);
  const scrollRef = useRef(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [validUser, setValidUser] = useState(null);
  // console.log(validUser);
  // console.log("chats", chats);

  useEffect(() => {
    const loadChats = async () => {
      setLoading(true);
      await dispatch(fetchChats());
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };
    loadChats();
  }, [dispatch]);
  
  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 5) {
          setLoadingMore(true);
          setTimeout(() => {
            setVisibleChats((prev) => prev + 10);
            setLoadingMore(false);
          }, 2000);
        }
      }
    };

    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    socket.on("onlineUsers", (users) => {
      setOnlineUsers(users);
    });
  }, [onlineUsers]);

    // Listen for new messages via socket
  useEffect(() => {
    socket.on("newChats", (updatedChats) => {
      // console.log("newChats received", updatedChats);
      // Update chats state with new chat data
      dispatch(fetchChats(updatedChats)); // You might need to adjust how `fetchChats` works to handle incoming chats
    });
  }, [dispatch]);

  return (
    <>
      <div
        ref={scrollRef}
        className="flex flex-col -space-y-1 overflow-y-scroll scrollbar-hide h-[87vh] pb-10"
      >
        {loading ? (
          <SkeletonLoading height={40} count={7} />
        ) : chats?.length > 0 ? (
          chats.slice(0, visibleChats).map((e) => {
            return (
              <div
                onClick={() => {
                  dispatch(setActiveChat(e));
                }}
                key={e._id}
                className={`flex items-center justify-between sm:gap-x-1 md:gap-x-1 mt-5 ${
                  activeChat._id === e._id ? "bg-[#f5f5f5]" : "bg-[#fff]"
                } cursor-pointer py-2 px-2`}
              >
                <div className="flex items-center gap-x-3 sm:gap-x-1 md:gap-x-3">
                  <img
                    className="w-6 h-6 sm:w-10 sm:h-10 rounded-[30px] shadow-lg object-cover"
                    src={getChatPhoto(e, activeUser)}
                    alt=""
                  />
                  <div>
                    <h5 className="text-[15px] sm:text-[16px] text-[#2b2e33] font-semibold">
                      {getChatName(e, activeUser)}
                    </h5>
                    <p className="text-[12px] sm:text-[12px] font-medium text-[#56585c]">
                      {e.latestMessage?.message.length > 30
                        ? e.latestMessage?.message.slice(0, 30) + "..."
                        : e.latestMessage?.message}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-end gap-y-[8px]">
                  <p className="text-[10px] sm:text-[10px] font-normal text-[#b0b2b3] tracking-wide">
                    {timeSinceNew(new Date(Date.parse(e.updatedAt)))}
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <NoContacts />
        )}
        {loadingMore && visibleChats < chats.length && <Loading />}
      </div>
    </>
  );
}

export default Contacts;
