import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BsEmojiLaughing, BsEmojiExpressionless } from "react-icons/bs";
import { updateMember, updateUser } from "../apis/auth";
import { setUserNameAndBio } from "../redux/activeUserSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

function MemberUpdate({ user, closeModal }) {
  // const [formData, setFormData] = useState(defaultData);
  const [isLoading, setIsLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const pageRoute = useNavigate();
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => state.activeUser);

  const [formData, setFormData] = useState({
    name: user.name,
    email: user.email,
    employee_code: user.employee_code,
    status: user.status,
  });

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateMember(user._id, formData);
      closeModal();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div
        ref={modalRef}
        className="relative p-4 w-full max-w-2xl"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="relative bg-white rounded-sm shadow dark:bg-gray-700">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Update Member
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-md text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={closeModal}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 md:p-5 space-y-4">
            <form onSubmit={handleOnSubmit} className="space-y-4">
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleOnChange}
                className="bg-gray-200 h-12 pl-3 text-gray-800 w-full rounded"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="email"
                value={formData.email}
                onChange={handleOnChange}
                className="bg-gray-200 h-12 pl-3 text-gray-800 w-full rounded"
                required
              />
              <input
                type="text"
                name="employee_code"
                placeholder="Employee CODE"
                value={formData.employee_code}
                onChange={handleOnChange}
                className="bg-gray-200 h-12 pl-3 text-gray-800 w-full rounded"
                required
              />
              <button
                type="submit"
                className="w-24 h-8 font-semibold text-white tracking-wide text-sm relative bg-gradient-to-r bg-green-600 hover:bg-green-700 rounded-sm"
              >
                {isLoading ? (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <lottie-player
                      src="https://assets2.lottiefiles.com/packages/lf20_h9kds1my.json"
                      background="transparent"
                      speed="1"
                      style={{ width: "50px", height: "50px" }}
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                ) : (
                  "Update"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberUpdate;
