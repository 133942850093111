import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RxCross2 } from "react-icons/rx";
import { useEffect } from "react";
import { searchUsers } from "../apis/auth";
import {
  addToGroup,
  removeUser,
  renameGroup,
  makeGroupAdmin,
  removeGroupAdmin,
} from "../apis/chat";
import { fetchChats } from "../redux/chatsSlice";
import Search from "./group/Search";
import { getChatName, getChatPhoto } from "../utils/logics";
import { IoIosCamera } from "react-icons/io";
import { toast } from "react-toastify";
import axios from "axios";
import { TbEdit } from "react-icons/tb";
import { BsCheck2 } from "react-icons/bs";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  pb: 1,
  borderRadius: "3px",
};

function Model(props) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [searchResults, setSearchResults] = useState([]);
  const [name, setName] = useState("");
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const { activeChat } = useSelector((state) => state.chats);
  const activeUser = useSelector((state) => state.activeUser);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [editable, setEditable] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  // console.log(activeChat.users);
  // console.log(activeChat.groupAdmin);
  const handleOpen = () => {
    setOpen(true);
    setName(getChatName(activeChat, activeUser));
  };
// console.log("activeUser", activeUser);
// console.log("members", members);

  const handleClose = () => {
    setOpen(false);
    setEditable(false);
    setSearch("");
    setSearchResults([]);
  };

  const handleClick = async (e) => {
    if (members.includes(e)) {
      return;
    }
    await addToGroup({ userId: e?._id, chatId: activeChat?._id });
    setMembers([...members, e]);
  };

  const makeAdmin = async (e) => {
    await makeGroupAdmin({ chatId: activeChat._id, userId: e._id });
    setMembers(
      members.map((member) => {
        if (member._id === e._id) {
          return { ...member, isAdmin: true };
        }
        return member;
      })
    );
    dispatch(fetchChats());
  };

  const removeAdmin = async (e) => {
    await removeGroupAdmin({ chatId: activeChat._id, userId: e._id });
    setMembers(
      members.map((member) => {
        if (member._id === e._id) {
          return { ...member, isAdmin: true };
        }
        return member;
      })
    );
    dispatch(fetchChats());
  };

  // const handleFileSelect = (event) => {
  //   const file = event.target.files[0];
  //   setFileName(file.name);
  //   console.log(file.name);
  // };

  // const updateBtn = async () => {
  //   if (name) {
  //     let data = await renameGroup({
  //       chatId: activeChat._id,
  //       chatName: name,
  //       photo: fileName,
  //     });
  //     console.log("data", data);
  //     if (data) {
  //       dispatch(fetchChats());
  //       setOpen(false);
  //     }
  //   }
  //   setOpen(false);
  // };

  const updateBtn = async () => {
    if (name) {
      const formData = {
        chatId: activeChat._id,
        chatName: name,
      };

      if (selectedFile) {
        formData.photo = selectedFile;
      }

      // console.log("formData", formData);
      let data = await renameGroup(formData);
      if (data) {
        dispatch(fetchChats());
        setOpen(false);
      }
    }
    setOpen(false);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setFileName(file.name);
  };

  const deleteSelected = async (ele) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      const res = await removeUser({ chatId: activeChat._id, userId: ele._id });
      if (res._id) {
        setMembers(members.filter((e) => e._id !== ele._id));
        dispatch(fetchChats());
      }
    }
    return;
  };

  const leaveGroup = async () => {
    const res = await removeUser({
      chatId: activeChat._id,
      userId: activeUser.id,
    });
    if (res._id) {
      dispatch(fetchChats());
      setOpen(false);
    }
    return;
  };

  const groupAdmin = activeChat.groupAdmin;

  const adminIds = groupAdmin.map((admin) => admin._id);

  const adminMembers = members.filter((member) =>
    adminIds.includes(member._id)
  );

  useEffect(() => {
    setMembers(activeChat?.users.map((e) => e));
  }, [activeChat]);

  const handleFormSearch = async (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const searchChange = async () => {
      setIsLoading(true);
      const { data } = await searchUsers(search);
      const filteredData = data.filter(
        (user) => !members.find((member) => member._id === user._id)
      );
      setSearchResults(filteredData);
      setIsLoading(false);
    };
    searchChange();
  }, [search, members]);

  useEffect(() => {
    setIsAdmin(groupAdmin.some((admin) => admin._id === activeUser.id));
  }, [activeUser.id, groupAdmin]);

  const API = (token) =>
    axios.create({
      baseURL: process.env.REACT_APP_SERVER_URL,
      headers: { Authorization: token },
    });

  return (
    <>
      <button onClick={handleOpen}>
        <img
          className="w-[40px] h-[40px] rounded-[25px]"
          alt="Profile Pic"
          src={getChatPhoto(activeChat, activeUser)}
        />
      </button>
      {activeChat?.isGroup ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} style={{ width: "40%" }}>
            <div className="flex items-center flex-col">
              <div
                style={{ position: "relative", display: "inline-block" }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <img
                  src={getChatPhoto(activeChat, activeUser)}
                  alt="Image"
                  className="w-[130px] h-[130px] rounded-[100%]"
                />
                {isHovered && (
                  <div
                    className="rounded-[100%]"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <label
                      htmlFor="file-input"
                      style={{
                        color: "white",
                        cursor: "pointer",
                        textAlign: "center",
                        fontSize: "10px",
                      }}
                    >
                      <IoIosCamera
                        className="text-[#e44d4d] w-[27px] h-[23px] center"
                        style={{
                          color: "white",
                          cursor: "pointer",
                          fontSize: "10px",
                          marginLeft: "35%",
                        }}
                      />
                      Change Profile Photo
                    </label>
                    <input
                      type="file"
                      id="file-input"
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                      accept="image/*"
                    />
                  </div>
                )}
              </div>
            </div>

            <h5 className="text-[20px] py-2 font-semibold tracking-wide text-center mt-2 text-[#fafafa] bg-[#166e48]">
              {!editable ? (
                <div>
                  {" "}
                  {getChatName(activeChat, activeUser)}{" "}
                  <button onClick={() => setEditable(!editable)}>
                    <TbEdit className="w-[21px] h-[21px] text-gray-300" />
                  </button>
                </div>
              ) : (
                <div>
                  <input
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    className=" bg-[#166e48] text-[20px] text-center px-2 w-[100%]"
                    type="text"
                    name="chatName"
                    placeholder="Group Name"
                    required
                  />
                </div>
              )}
            </h5>
            <div>
              {/* <h6 className="text-[14px] text-[#111b21] tracking-wide font-semibold">
                Members
              </h6> */}
              <div className="flex flex-col -space-y-1 overflow-y-scroll h-[50vh] pb-1 w-full px-2">
                {members.length > 0 &&
                  members?.map((e) => {
                    {
                      {
                        {
                          /* {
                          return (
                          <button
                            button
                            className="flex items-center gap-x-1 bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400"
                          >
                            <span className="text-[10px]">
                              {e._id === activeUser.id ? "You" : e.name}
                            </span>
                            <RxCross2 onClick={() => deleteSelected(e)} />
                          </button>
                        ); 
                        } */
                        }

                        return (
                          <div
                            key={e._id}
                            className="flex items-center justify-between sm:gap-x-1 md:gap-x-1 mt-3 cursor-pointer py-2 px-2 hover:bg-gray-100"
                          >
                            <div className="flex items-center gap-x-3 sm:gap-x-1 md:gap-x-3 justify-between w-full">
                              <div className=" flex justify-between  sm:gap-x-2 md:gap-x-2">
                                <img
                                  className="w-10 h-10 sm:w-10 sm:h-10 rounded-[30px] shadow-lg object-cover"
                                  src={
                                    e._id === activeUser.id
                                      ? activeUser.profilePic
                                      : e.profilePic
                                  }
                                  alt="profilePic"
                                />
                                <h5 className="text-[13.6px] sm:text-[16px] text-[#2b2e33] font-bold mt-1">
                                  {e._id === activeUser.id ? "You" : e.name}
                                </h5>
                              </div>
                              {adminMembers.some(
                                (admin) => admin._id === e._id
                              ) && (
                                <span className="inline-block min-w-[60px] text-center text-sm rounded-md bg-opacity-25 text-success-500 bg-success-500">
                                  admin
                                </span>
                              )}
                              {adminMembers.some(
                                (admin) => admin._id === activeUser.id
                              ) && (
                                <div>
                                  <Menu
                                    as="div"
                                    className="relative inline-block text-left"
                                  >
                                    <div>
                                      <MenuButton className="">
                                        <ChevronDownIcon
                                          aria-hidden="true"
                                          className="-mr-1 h-5 w-5 text-gray-600"
                                        />
                                      </MenuButton>
                                    </div>
                                    <MenuItems
                                      transition
                                      className="absolute right-0 z-10 mt-2 w-44 origin-top-right rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                    >
                                      <div className="py-1">
                                        {adminMembers.some(
                                          (admin) => admin._id === e._id
                                        ) ? (
                                          <MenuItem>
                                            <a
                                              // add a removeAdmin function
                                              onClick={() => removeAdmin(e)}
                                              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                            >
                                              Dismiss as Admin
                                            </a>
                                          </MenuItem>
                                        ) : (
                                          <MenuItem>
                                            <a
                                              onClick={() => makeAdmin(e)}
                                              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                            >
                                              Make Admin
                                            </a>
                                          </MenuItem>
                                        )}
                                        <MenuItem>
                                          <a
                                            onClick={() => deleteSelected(e)}
                                            href="#"
                                            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                          >
                                            Remove
                                          </a>
                                        </MenuItem>
                                      </div>
                                    </MenuItems>
                                  </Menu>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      }
                    }
                  })}
              </div>
              <div className="px-2">
                {isAdmin && (
                  <form
                    className="mt-1 flex flex-col gap-y-3"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <input
                      onChange={handleFormSearch}
                      className="border-[#c4ccd5] border-[1px] text-[13.5px] py-[4px] px-2 w-[100%]"
                      type="text"
                      name="users"
                      placeholder="add users"
                    />
                  </form>
                )}

                <Search
                  isLoading={isLoading}
                  handleClick={handleClick}
                  search={search}
                  searchResults={searchResults}
                />

                <div className="flex justify-end gap-x-3 mt-3">
                  <button
                    onClick={updateBtn}
                    className="bg-[#0086ea] transition hover:bg-[#00A1C9]  px-4 py-1 text-[10.6px] tracking-wide text-[#fff]"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => leaveGroup()}
                    className="bg-[#880808] hover:bg-[#A52A2A] transition delay-150 px-4 py-1 text-[10.6px] tracking-wide text-[#fff]"
                  >
                    Leave
                  </button>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      ) : (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="w-[250px] h-[250px] flex flex-col items-center justify-center -mt-4">
              <img
                className="w-[70px] h-[70px] rounded-[35px] shadow-lg"
                src={getChatPhoto(activeChat, activeUser)}
                alt=""
              />
              <h2 className="text-[17px] tracking-wider font-semibold text-[#313439] mt-5">
                {getChatName(activeChat, activeUser)}
              </h2>

              <h3 className="text-[14px] font-semibold text-[#268d61]">
                {!activeChat?.isGroup &&
                activeChat?.users[0]?._id === activeUser.id
                  ? activeChat?.users[1]?.email
                  : activeChat?.users[0]?.email}
              </h3>
              <div className="flex flex-col items-start">
                <h5 className="text-[13px]">
                  {!activeChat?.isGroup &&
                  activeChat?.users[0]?._id === activeUser.id
                    ? activeChat?.users[1]?.bio
                    : activeChat?.users[0]?.bio}
                </h5>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default Model;
