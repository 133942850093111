import React, { useState, useEffect, useRef } from "react";

function ChatActivity({ user, chatActivity = [], closeChatModal }) {
  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef(null);
  console.log(user);
  console.log(chatActivity);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeChatModal();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div
        ref={modalRef}
        className="relative p-4 w-full max-w-2xl"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="relative bg-white rounded-sm shadow dark:bg-gray-700">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Chat Activity for {user?.name}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-md text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={closeChatModal}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 md:p-5 space-y-4">
            <form className="space-y-4">
              <select
                id="small"
                className="block w-full p-2 mb-6 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option selected>Choose a chat activity</option>
                {chatActivity.map((activity) => (
                  <option key={activity._id} value={activity._id}>
                    {activity?.isGroup
                      ? activity?.chatName
                      : activity?.users[0]?._id === user.id
                      ? activity?.users[1]?.name
                      : activity?.users[0]?.name}
                  </option>
                ))}
              </select>
              <button
                type="submit"
                className="w-28 h-10 font-semibold text-white tracking-wide text-sm relative bg-gradient-to-r bg-green-600 hover:bg-green-700 rounded-sm"
              >
                {isLoading ? (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <lottie-player
                      src="https://assets2.lottiefiles.com/packages/lf20_h9kds1my.json"
                      background="transparent"
                      speed="1"
                      style={{ width: "50px", height: "50px" }}
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                ) : (
                  "Search"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatActivity;
