import React, { useState, useEffect } from "react";
import { BsPlusLg } from "react-icons/bs";
import { Modal, Box } from "@mui/material";
import { searchUsers } from "../apis/auth";
import { RxCross2 } from "react-icons/rx";
import { createGroup } from "../apis/chat";
import { fetchChats } from "../redux/chatsSlice";
import { useDispatch } from "react-redux";
import Search from "./group/Search";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  pb: 1,
  borderRadius: "3px",
};

function Group({ open, onClose }) {
  const dispatch = useDispatch();
  // const [open, setOpen] = useState(false);
  const [chatName, setChatName] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  // const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSearch("");
    setSelectedUsers([]);
    onClose(); 
  };

  const handleFormSearch = async (e) => {
    setSearch(e.target.value);
  };

  const handleClick = (user) => {
    if (!selectedUsers.includes(user)) {
      setSelectedUsers([...selectedUsers, user]);
      setSearchResults(searchResults.filter((u) => u._id !== user._id));
    }
  };

  const deleteSelected = (user) => {
    setSelectedUsers(selectedUsers.filter((u) => u._id !== user._id));
  };

  const handleSubmit = async () => {
    if (selectedUsers.length >= 2) {
      await createGroup({
        chatName,
        users: JSON.stringify(selectedUsers.map((user) => user._id)),
      });
      dispatch(fetchChats());
      handleClose();
    }
  };

  useEffect(() => {
    const searchChange = async () => {
      setIsLoading(true);
      const { data } = await searchUsers(search);
      const filteredData = data.filter(
        (user) =>
          !selectedUsers.some((selectedUser) => selectedUser._id === user._id)
      );
      setSearchResults(filteredData);
      setIsLoading(false);
    };
    if (search) {
      searchChange();
    } else {
      setSearchResults([]);
    }
  }, [search, selectedUsers]);

  return (
    <>
      {/* <button
        className="mt-1 transition duration-150 ease-in-out"
        onClick={handleOpen}
      >
        <div className="flex justify-start border-r-2">
          <button className="text-[11px] font-normal tracking-wide flex items-center gap-x-1 bg-[#f6f6f6] text-[#1f2228] py-1 -mb-7 mt-2  px-2">
            New Group <BsPlusLg />
          </button>
        </div>
      </button> */}
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h5
            className="text-[20px] py-2 font-semibold tracking-wide text-center text-[#fafafa] bg-[#166e48]"
            style={{ boderRadius: "3px" }}
          >
            New Group
          </h5>

          <form
            onSubmit={(e) => e.preventDefault()}
            className="flex flex-col gap-y-0 mt-3"
          >
            <div className="flex flex-col -space-y-1 overflow-y-scroll h-[25vh] pb-1 w-full px-2">
              {selectedUsers.length > 0 &&
                selectedUsers?.map((e) => {
                  {
                    {
                      { 
                        /* {
                          return (
                          <button
                            button
                            className="flex items-center gap-x-1 bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400"
                          >
                            <span className="text-[10px]">
                              {e._id === activeUser.id ? "You" : e.name}
                            </span>
                            <RxCross2 onClick={() => deleteSelected(e)} />
                          </button>
                        ); 
                        } */
                      }

                      return (
                        <div
                          key={e._id}
                          className="flex items-center justify-between sm:gap-x-1 md:gap-x-1 mt-3 cursor-pointer py-2 px-2 hover:bg-gray-100"
                        >
                          <div className="flex items-center gap-x-3 sm:gap-x-1 md:gap-x-3 justify-between w-full">
                            <div className=" flex justify-between  sm:gap-x-2 md:gap-x-2">
                              <img
                                className="w-10 h-10 sm:w-10 sm:h-10 rounded-[30px] shadow-lg object-cover"
                                src={e.profilePic}
                                alt="profilePic"
                              />
                              <h5 className="text-[13.6px] sm:text-[16px] text-[#2b2e33] font-bold mt-1">
                                {e.name}
                              </h5>
                            </div>
                            <RxCross2 onClick={() => deleteSelected(e)} />
                          </div>
                        </div>
                      );
                    }
                  }
                })}
            </div>
            <div className="flex flex-col overflow-y-scroll scrollbar-hide h-[35.5vh] pb-1 w-full px-2">
              <div className="flex flex-col space-y-4 mt-4 px-3">
                <div className="input-area">
                  <label htmlFor="current-password" className="form-label">
                    Group Name
                    <span className="text-red-700 text-lg">*</span>
                  </label>
                  <input
                    id="current-password"
                    type="text"
                    className="form-control"
                    placeholder="Group Name"
                    name="chatName"
                    onChange={(e) => setChatName(e.target.value)}
                  />
                </div>
                <div className="input-area">
                  <label htmlFor="new-password" className="form-label">
                    Users
                  </label>
                  <input
                    id="new-password"
                    type="text"
                    className="form-control"
                    placeholder="User Name"
                    name="users"
                    onChange={handleFormSearch}
                  />
                </div>
                <div className="flex justify-end gap-x-3 mt-3">
                  <button
                    type="submit"
                    className="bg-[#0086ea] text-[#fff] text-[12px] font-medium px-3 py-1 tracking-wide"
                    onClick={handleSubmit}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              {/* {selectedUsers.map((user) => (
                <button
                  key={user._id}
                  onClick={() => deleteSelected(user)}
                  className="flex items-center gap-x-1 bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400"
                >
                  <span>{user.name}</span>
                  <RxCross2 />
                </button>
              ))} */}
            </div>

            <Search
              isLoading={isLoading}
              handleClick={handleClick}
              search={search}
              searchResults={searchResults}
            />
            {/* <input
              onChange={(e) => setChatName(e.target.value)}
              className="border-[#c4ccd5] border-[1px] text-[13.5px] py-[4px] px-2 w-[100%]"
              type="text"
              name="chatName"
              placeholder="Group Name"
              required
            />
            <input
              onChange={handleFormSearch}
              className="border-[#c4ccd5] border-[1px] text-[13.5px] py-[4px] px-2 w-[100%]"
              type="text"
              name="users"
              placeholder="add users"
            /> */}

            {/* 
            <div className="flex justify-end mt-3">
              <button
                onClick={handleSubmit}
                className="bg-[#0086ea] text-[#fff] text-[15px] font-medium px-2 py-1 tracking-wide"
                type="submit"
              >
                Create
              </button>
            </div> */}
          </form>
        </Box>
      </Modal>
    </>
  );
}

export default Group;
