import axios from "axios";
import { toast } from "react-toastify";
import chnagepicturereal from "../components/Profile";

const API = (token) =>
  axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: { Authorization: token },
  });
let url = process.env.REACT_APP_SERVER_URL;

export const loginUser = async (body) => {  
  try {
    return await axios.post(`${url}/auth/login`, body);
  } catch (error) {
    console.log("error in login user api");
  }
};

export const googleAuth = async (body) => {
  try {
    return await axios.post(`${url}/api/google`, body);
  } catch (error) {
    console.log(error);
  }
};

export const registerUser = async (body) => {
  try {
    return await axios.post(`${url}/auth/register`, body);
  } catch (error) {
    console.log("error in register api");
  }
};

export const validUser = async () => {
  try {
    const token = localStorage.getItem("userToken");
    const { data } = await API(token).get(`/auth/valid`, {
      headers: { Authorization: token },
    });
    return data;
  } catch (error) {
    console.log("error in valid user api");
  }
};

// export const validAdmin = async () => {
//   try {
//     const token = localStorage.getItem("userToken");
//     const { data } = await API(token).get(`/auth/validAdmin`, {
//       headers: { Authorization: token },
//     });
//     return data;
//   } catch (error) {
//     console.log("error in valid user api");
//   }
// };

export const searchUsers = async (id) => {
  try {
    const token = localStorage.getItem("userToken");

    return await API(token).get(`/api/user?search=${id}`);
  } catch (error) {
    console.log("error in search users api");
  }
};

export const searchChats = async (id) => {
  console.log(id);
  
  try {
    const token = localStorage.getItem("userToken");

    return await API(token).get(`/api/chats?search=${id}`);
  } catch (error) {
    console.log("error in search users api");
  }
};

export const updateUser = async (id, body) => {
  console.log("data received");
  try {
    const token = localStorage.getItem("userToken");
    const { data } = await API(token).patch(`/api/users/update/${id}`, body);
    console.log(data);
    return data;
  } catch (error) {
    console.log("error in update user api");
    toast.error("Something Went Wrong.try Again!");
  }
};

export const profiepicturechnage = async (id, body) => {
  try {
    const token = localStorage.getItem("userToken");

    const { data } = await API(token).post(
      `/api/users/profilepicturechange/${id}`,
      body
    );
    return data;
  } catch (error) {
    console.log("error in update profile picture");
    toast.error("Something Went Wrong.try Again!");
  }
};

export const checkValid = async () => {
  const data = await validUser();
  if (!data?.user) {
    window.location.href = "/login";
  } else {
    window.location.href = "/chats";
  }
};

// export const checkValidAdmin = async () => {
//   const data = await validAdmin();
//   if (!data?.user) {
//     window.location.href = "/admin/login";
//   } else {
//     window.location.href = "/admin";
//   }
// };
  
export const loginAdmin = async (body) => {
  try {
    return await axios.post(`${url}/auth/admin/login`, body);
  } catch (error) {
    console.log("error in login user api");
  }
};

export const getUsers = async (id) => {
  try {
    const token = localStorage.getItem("userToken");
    
    return await API(token).get('/api/users');
  } catch (error) {
    console.log("error in search users api");
  }
};

export const updateMember = async (id, body) => {
  try {
    const response = await axios.patch(`${process.env.REACT_APP_SERVER_URL}/api/member/update/${id}`, body);
    return response;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

// export const updateMember = async (id, body) => {
//   try {
//     const token = localStorage.getItem("userToken");
//     const response = await API(token).patch(`/api/member/${id}`, body);
//     const updatedMember = response.data;
//     console.log("updated member", updatedMember);
//     return updatedMember;
//   } catch (error) {
//     console.error(`Error updating member: ${error}`);
//     toast.error("Something Went Wrong. Try Again!");
//     throw error; // Optionally rethrow the error for the caller to handle
//   }
// };

// export const updateMember = async (id, body) => {
//   try {
//     const token = localStorage.getItem("userToken");
//     const { data } = await API(token).patch(`/api/member/${id}`, body);
//     console.log(token);
//     console.log(data);
//     return data;
//   } catch (error) {
//     console.log("error in update user api");
//     toast.error("Something Went Wrong.try Again!");
//   }
// };
