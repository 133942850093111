import React, { useState, useMemo, useEffect } from "react";
import { getUsers } from "../../../apis/auth"; // Adjust the import path as necessary
import Loading from "../../../components/ui/Loading";
import {
  useTable,
  useRowSelect,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { FaEye, FaTrashAlt } from "react-icons/fa";
import { BsPencilSquare } from "react-icons/bs";
import MemberUpdate from "../../../pages/MemberUpdate";
import ChatActivity from "../../ChatActivity";
import { fetchChatActivity } from "../../../apis/chat";
import "../../../assets/css/app.css";
import { useSelector } from "react-redux";
import { FaTrashRestoreAlt } from "react-icons/fa";
import { io } from "socket.io-client";
import DataTable from "datatables.net-dt";
import GlobalFilter from "./GlobalFilter";
import PasswordReset from "../../../pages/PasswordReset";
import { FaCircle } from "react-icons/fa";

const ENDPOINT = process.env.REACT_APP_SERVER_URL;

const socket = io(ENDPOINT);

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <input
        type="checkbox"
        ref={resolvedRef}
        {...rest}
        className="table-checkbox"
      />
    );
  }
);

function DashboardCard07({refreshData}) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [chatActivity, setChatActivity] = useState([]);
  const [action, setAction] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [resetPass, setResetPass] = useState(false);

  useEffect(() => {
    reload();
  }, [])

  useEffect(() => {
    if (refreshData) {
      reload();
    }
  }, [refreshData])

  const onlineButNotInUsers = onlineUsers.filter((onlineUser) => {
    return !users.find((user) => user._id === onlineUser._id);
  });

  const openModal = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    reload();
    setIsModalOpen(false);
  };

  const openResetModal = (user) => {
    setSelectedUser(user);
    setResetPass(true);
  };
  const closeResetModal = () => {
    setSelectedUser(null);
    reload();
    setResetPass(false);
  };
  const openChatModal = async (user) => {
    try {
      const response = await fetchChatActivity(user._id);
      setChatActivity(response);
      console.log(response);

      setSelectedUser(user);
      setIsChatModalOpen(true);
    } catch (error) {
      console.error("Error fetching chat activity:", error);
    }
  };

  const closeChatModal = () => {
    setSelectedUser(null);
    setIsChatModalOpen(false);
  };

  const openAction = (user) => {
    setSelectedUser(user);
    setAction(true);
  };
  const closeAction = () => {
    setAction(false);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await getUsers();
        setUsers(data.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    socket.on("onlineUsers", (users) => {
      setOnlineUsers(users);
    });
  }, [onlineUsers]);

  const disableMember = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/chat/disable/${selectedUser._id}`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.ok) {
        closeAction();
        reload();
      } else {
        const errorText = await response.text();
        console.error(
          `Error disabling member: ${response.status} - ${errorText}`
        );
      }
    } catch (error) {
      console.error("Network error disabling member:", error);
    }
  };

  const updatePassword = async (user) => {
    const confirmUpdate = window.confirm(
      "Do you really want to update the password for user with ID " +
        user._id +
        "?"
    );

    if (confirmUpdate) {
      console.log("Password update confirmed for user:", user._id);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/reset-password`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: user.email }),
          }
        );
        console.log(response);

        const data = await response.json();
        console.log(data);

        if (response.ok) {
          console.log("Password reset successfully:", data);
          alert("Password reset successfully.");
        } else {
          console.log("Password reset failed:", data);
          alert("Password reset failed: " + data.error);
        }
      } catch (error) {
        console.error("Error resetting password:", error);
        alert("An error occurred while resetting the password.");
      }
    } else {
      console.log("Password update canceled for user:", user._id);
    }
  };

  const reload = async () => {
    try {
      const data = await getUsers();
      setUsers(data.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const COLUMNS = [
    {
      Header: "",
      accessor: "onlineStatus",
      Cell: ({ row }) => {
        const user = row.original;
        const isOnline = onlineUsers.find(
          (onlineUser) => onlineUser.userId === user._id
        );
        return (
          <span>
            {isOnline && <span className="text-success-500"><FaCircle/></span>}
          </span>
        );
      },
    },

    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value, row }) => {
        const user = row.original;
        const isOnline = onlineUsers.find(
          (onlineUser) => onlineUser.userId === user._id
        );

        return (
          <span className=" flex justify-between">
            {value}
          </span>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ value }) => {
        return <span>{value}</span>;
      },
    },
    {
      Header: "Employee Code",
      accessor: "employee_code",
      Cell: ({ value }) => {
        return <span>{value}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => {
        const isActive = value == true;
        const isInactive = value == false;
        const statusText = isActive
          ? "Enabled"
          : isInactive
          ? "Disabled"
          : "Unknown";

        return (
          <span className="block w-full">
            <span
              className={`inline-block px-3 min-w-[90px] text-center mx-auto py-1 rounded-[999px] bg-opacity-25  ${
                isActive ? "text-success-500 bg-success-500" : ""
              } 
              ${isInactive ? "text-warning-500 bg-warning-500" : ""}
               `}
            >
              {statusText}
            </span>
          </span>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => {
        const user = row.original;
        const isDisabled = user.status == "false";

        return (
          <div className="flex space-x-3 rtl:space-x-reverse">
            <button
              className="action-btn"
              type="button"
              onClick={() => openModal(user)}
            >
              <BsPencilSquare />
            </button>
            <button
              className="action-btn"
              type="button"
              onClick={() => openResetModal(user)}
            >
              <FaTrashRestoreAlt />
            </button>
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "enable/disable",
      Cell: ({ row }) => {
        const user = row.original;
        const isDisabled = user.status == false;

        return (
          <div className="flex space-x-3 rtl:space-x-reverse">
            <button
              className={` ${
                isDisabled
                  ? "py-1 px-3 rounded-sm inline-flex justify-center btn-success"
                  : "py-1 px-3 rounded-sm inline-flex justify-center btn-danger"
              }`}
              type="button"
              onClick={() => openAction(user)}
            >
              {isDisabled ? "Enable" : "Disable"}
            </button>
          </div>
        );
      },
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  const tableInstance = useTable(
    {
      columns,
      data: users,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    setGlobalFilter,
    prepareRow,
  } = tableInstance;

  const { globalFilter, pageIndex, pageSize } = state;

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="col-span-full xl:col-span-12 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <div class="card">
        <div className="flex justify-between items-center pe-7">
          <header class="card-header noborder">
            <h4 class="card-title">SRFC CHAT USERS</h4>
          </header>
          <div>
            <div>
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            </div>
          </div>
        </div>
        <div class="card-body px-6 pb-6">
          <div class="overflow-x-auto -mx-6 dashcode-data-table">
            <span class="col-span-8 hidden"></span>
            <span class="col-span-4 hidden"></span>
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden">
                <table class="min-w-full divide-y divide-slate-100 table-fixed dark:divide-slate-700 data-table">
                  <thead class="bg-slate-200 dark:bg-slate-700">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        key={headerGroup.id}
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            scope="col"
                            className="table-th"
                            key={column.id}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? ""
                                  : ""
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody
                    class="bg-white divide-y divide-slate-100 dark:bg-slate-800 dark:divide-slate-700"
                    {...getTableBodyProps()}
                  >
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={row.id}>
                          {row.cells.map((cell) => (
                            <td
                              {...cell.getCellProps()}
                              className="table-td"
                              key={cell.column.id}
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:flex md:space-y-0 space-y-5 mb-4 px-5 justify-between mt-4 items-center">
        <div className="flex items-center space-x-3 rtl:space-x-reverse">
          <select
            className="form-control py-2 w-max"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 25, 50].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
          <span className="text-sm font-medium text-slate-600 dark:text-slate-300">
            Page {pageIndex + 1} of {pageOptions.length}
          </span>
        </div>
        <ul className="flex items-center space-x-3 rtl:space-x-reverse">
          <li className="text-xl leading-4 text-slate-900 dark:text-white rtl:rotate-180">
            <button
              className={`${
                !canPreviousPage ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {/* <Icon icon="heroicons:chevron-double-left-solid" /> */}
            </button>
          </li>
          <li className="text-sm leading-4 text-slate-900 dark:text-white rtl:rotate-180">
            <button
              className={`${
                !canPreviousPage ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Prev
            </button>
          </li>
          {pageOptions.map((page, pageIdx) => (
            <li key={pageIdx}>
              <button
                aria-current="page"
                className={`${
                  pageIdx === pageIndex
                    ? "bg-slate-900 dark:bg-slate-600 dark:text-slate-200 text-white font-medium"
                    : "bg-slate-100 dark:bg-slate-700 dark:text-slate-400 text-slate-900 font-normal"
                } text-sm rounded leading-[16px] flex h-6 w-6 items-center justify-center transition-all duration-150`}
                onClick={() => gotoPage(pageIdx)}
              >
                {pageIdx + 1}
              </button>
            </li>
          ))}
          <li className="text-sm leading-4 text-slate-900 dark:text-white rtl:rotate-180">
            <button
              className={`${
                !canNextPage ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
          </li>
          <li className="text-xl leading-4 text-slate-900 dark:text-white rtl:rotate-180">
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              className={`${
                !canNextPage ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {/* <Icon icon="heroicons:chevron-double-right-solid" /> */}
            </button>
          </li>
        </ul>
      </div>

      {isModalOpen && (
        <MemberUpdate user={selectedUser} closeModal={closeModal} />
      )}
      {resetPass && (
        <PasswordReset user={selectedUser} closeResetModal={closeResetModal} />
      )}

      {isChatModalOpen && (
        <ChatActivity
          user={selectedUser}
          chatActivity={chatActivity}
          closeChatModal={closeChatModal}
        />
      )}

      {action && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 top-0 right-0 left-0 flex items-center justify-center z-50">
          <div class="relative p-4 w-full max-w-md max-h-full">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                type="button"
                class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="popup-modal"
                onClick={closeAction}
              >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
              <div class="p-4 md:p-5 text-center">
                <svg
                  class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                  Are you sure you want to disable this member?
                </h3>
                <button
                  data-modal-hide="popup-modal"
                  type="button"
                  class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                  onClick={disableMember}
                >
                  Yes, I'm sure
                </button>
                <button
                  data-modal-hide="popup-modal"
                  type="button"
                  class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  onClick={closeAction}
                >
                  No, cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DashboardCard07;
