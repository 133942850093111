// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Component }) => {
  const token = localStorage.getItem("userToken");
  return token ? Component : <Navigate to="/admin/login" />;
};

export default PrivateRoute;
